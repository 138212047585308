<template>
  <div>
    客户群欢迎语
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
